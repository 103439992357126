import { useContext, useState } from "react";
import { AuthContext } from "../../../../../contexts";
import { errorToaster, openWindow } from "../../../../../api";
import { Button } from "./Button";
import { faExternalLinkAlt, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const ShowInfo = ({ id }) => {
  const authContext = useContext(AuthContext);
  const [ pending, isPending ] = useState(false)

  const handleClick = (e) => {
    isPending(true);
    errorToaster(
      openWindow(id, authContext.current.id, 'information')
      .finally(_ => isPending(false))
    );
  }

  return (
    <Button type="button"
      variant="primary"
      data-tooltip-id="tip"
      data-tooltip-html="Open in-game profile"
      onClick={handleClick} disabled={pending}>
      <FontAwesomeIcon fixedWidth icon={!pending ? faExternalLinkAlt : faSpinner} spin={pending} />
    </Button>
  )
}

export default ShowInfo;
