import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Buttons } from "../../Components/Form";
import SkillsHelp from "./SkillsHelp";
import { useApi } from "../../api";
import CharacterSkills from "./CharacterSkills";
import Spinner from "../../Components/Spinner";

const TitleDOM = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  gap: 16px;
  justify-content: space-between;

  h2 {
    font-weight: 700;
    font-size: 26px;
    line-height: 1.35;
    margin: 0px;
  }
`;

const SkillCategoryDisplay = ({ category, mastery, hull, skills }) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const [ tiers ] = useApi(`/api/skills/category/${category.id}/tiers`);

  const onClick = (mastery) => {
    queryParams.set("mastery", mastery);
    navigate({
      search: queryParams.toString()
    });
  }

  return (
    <>
      {tiers ? (
        <>
          <TitleDOM>
            <div>
              <h2>{category.name}</h2>
            </div>
            <Buttons>
              { tiers && tiers.map((tier, key) => (
                <Button
                  key={key}
                  variant={mastery === tier.name ? 'primary' : null}
                  onClick={e => onClick(tier.name)}
                >
                  {tier.name}
                </Button>
              ))}
            </Buttons>
          </TitleDOM>
          <SkillsHelp />
          { tiers && (
            <CharacterSkills char_skills={skills} category={category} tier={tiers?.find(tier => tier.name === mastery)} />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
}

export default SkillCategoryDisplay;
