import { Card, Details } from "./components";

const LastUpdated = ({ last_updated }) => {
  return (
    <Card>
      <div>
        <Details>
          <p>Last Updated</p>
          <div>
            { (last_updated === undefined || last_updated === null) ? 'Never' : `${last_updated}`}
          </div>
        </Details>
      </div>
    </Card>
  )
}

export default LastUpdated;
