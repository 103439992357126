import { useMemo, useState } from "react";
import { Button } from "./Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStream } from "@fortawesome/free-solid-svg-icons";
import { SkillsModal } from "../../../../Skills";

const ViewSkills = ({ character, hull, skill_categories }) => {
  const [ open, setOpen ] = useState(false);

  const category = useMemo(() => {
    if(!skill_categories) {
      return undefined;
    } else {
      return skill_categories?.find(category => category.name === hull)
    }
  }, [skill_categories, hull])

  return (
    <>
      <Button variant='primary'
        data-tooltip-id="tip"
        data-tooltip-html={`View ${character?.name}'s skills`}
        onClick={_ => setOpen(true)}
      >
        <FontAwesomeIcon fixedWidth icon={faStream} />
      </Button>
      {category && open && (
        <SkillsModal character={character} hull={hull} open={open} setOpen={setOpen} category={category} />
      )}
    </>
  )
}

export default ViewSkills;
