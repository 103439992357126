import { useApi } from "../../api";
import { InputGroup, Button, Buttons, AButton } from "../../Components/Form";
import { Fitout, ImplantOut } from "./FittingSortDisplay";
import { PageTitle } from "../../Components/Page";
import { useLocation, useNavigate } from "react-router-dom";
import { usePageTitle } from "../../Util/title";
import {useEffect, useMemo, useState} from "react";

export function Fits() {
  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();
  const [fitCategories] = useApi(`/api/fittings/categories`);
  let queryTier = queryParams.get("Tier") || "Starter";
  const [tier, setTier] = useState({name: 'Starter', notes: null});
  const setQueryTier = (newTier) => {
    queryParams.set("Tier", newTier.name);
    setTier(newTier);
    navigate({search: queryParams.toString()})
  };

  useEffect(() => {
    if(fitCategories === null){
      setTier({name: 'Starter', notes: null});
    } else {
     setTier(fitCategories.find(category => category.name === queryTier));
    }
  }, [queryTier, fitCategories])

  return <FitsDisplay tier={tier} setTier={setQueryTier} fitCategories={fitCategories} />;
}

function FitsDisplay({ tier, setTier = null, fitCategories }) {
  usePageTitle(`${tier.name} Fits`);

  const [fitData] = useApi(`/api/fittings`);

  const filtered_fits = useMemo(() => {
    if(fitData === null) {
      return [];
    }
    return fitData['fittingdata'][tier.name]
  }, [tier, fitData])

  const notes = useMemo(() => {
    if(fitData === null) {
      return [];
    }
    return fitData['notes']
  }, [fitData])

  const seperated_fit_data = useMemo(() => {
    if(fitCategories === undefined || fitCategories === null){
      return [];
    }
    let data = [];
    let current_group = [];
    fitCategories.forEach((category) => {
      current_group.push(category);
      if(category.seperate || category === fitCategories[fitCategories.length - 1]) {
        data.push(current_group);
        current_group = [];
      }
    })
    return data;
  }, [fitCategories])

  if (fitData === null) {
    return <em>Loading fits...</em>;
  }

  return (
    <>
      <PageTitle>FITS</PageTitle>
      <AButton href="/skills" style={{ float: "right" }}>
        Skill Plans
      </AButton>
      {setTier != null && fitCategories !== null && (
        <Buttons style={{ marginBottom: "0.5em" }}>
          { seperated_fit_data.map((group, groupKey) => {
            return (
              <InputGroup key={groupKey}>
                { group.map((category, key) => {
                  return (<Button key={key} active={tier.name === category.name ? 'true' : null} onClick={(evt) => setTier(category)}>
                    {category.name}
                  </Button>)
                })}
              </InputGroup>
            )
          })}
          <InputGroup>


          </InputGroup>
          <InputGroup>
            <AButton
              href={`https://${window.location.host}/wiki/guides/travelling-between-focuses#incursion-ship-travel-fits`}
              target="_blank"
            >
              Travel
            </AButton>
          </InputGroup>
        </Buttons>
      )}
      <ImplantOut />
      <Fitout data={filtered_fits} data_notes={notes} tier={tier} />
    </>
  );
}
