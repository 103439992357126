import { useContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts";
import { usePageTitle } from "../../Util/title";
import { useApi } from "../../api";
import CharacterSkills from "./CharacterSkills";
import Tabs from "./Tabs";
import SkillCategoryDisplay from "./SkillCategoryDisplay";
import { Modal } from "../../Components/Modal";
import { Box } from "../../Components/Box";
import ModalTitle from "./ModalTitle";


const Skills = () => {
  const authContext = useContext(AuthContext);
  const queryParams = new URLSearchParams(useLocation().search);

  // Make 'basic' the default mastery to display if parameter is not specified.
  // Alias 'basic' to 'min', as that is its name in the object returned by the backend.
  const defaultMastery = "Basic"
  const mastery = queryParams.get("mastery") ?? defaultMastery

  return <Page
    characterId={queryParams.get("character_id") || authContext?.current.id}
    hull={queryParams.get("hull") || "Megathron"}
    mastery={mastery}
  />
}

const SkillsModal = ({ character, hull, open, setOpen, category }) => {
  const [skills] = useApi(`/api/skills?character_id=${character.id}`);
  const [ mastery, setMastery ] = useState('Basic');

  const [ tiers ] = useApi(`/api/skills/category/${category.id}/tiers`);

  let tier = useMemo(() => {
    if(!tiers){
      return undefined;
    }
    let found_tier = tiers?.find(tier => tier.name === mastery);
    if(found_tier !== undefined){
      return found_tier;
    } else {
      return tiers[0];
    }
  }, [tiers, mastery])

  return (
    <Modal open={open} setOpen={setOpen}>
      <Box>
        {tiers && (
          <>
            <ModalTitle character={character} tier={tier} setMastery={setMastery} hull={hull} tiers={tiers} />
            <CharacterSkills tier={tier} char_skills={skills} category={category} hidePlans />
          </>
        )}
      </Box>
    </Modal>
  )
}

const Page = ({ characterId, hull, mastery }) => {
  const [ basicInfo ] = useApi(`/api/pilot/info?character_id=${characterId}`, true);
  const [ skills ] = useApi(`/api/skills?character_id=${characterId}`, true);
  const [ categories ] = useApi(`/api/skills/categories`);

  usePageTitle(basicInfo !== undefined ? `${basicInfo?.name}'s Skills` : 'Skills');
  return (
    <>
      <Tabs selectedHull={hull} categories={categories} />
      {categories && (
        <SkillCategoryDisplay
          category={categories?.find(category => category.name === hull)}
          mastery={mastery}
          hull={hull}
          skills={skills} />
      )}
    </>
  )
}

export default Skills;
export { SkillsModal };
