import styled from "styled-components";
import SkillRow from "./SkillRow";
import CopyToSkillplan from "./EveSkillPlan";
import Spinner from "../../Components/Spinner";
import { useApi } from "../../api";
import { useMemo } from "react";

const SkillSheet = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
  gap: 16px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, minmax(0px, 1fr));
  }

  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }

  @media (max-width: 770px) {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
`;

const SkillCategory = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  -webkit-box-pack: start;
  justify-content: flex-start;
  gap: 10px;
  padding: 16px;

  h3 {
    font-size: 16px;
    line-height: 1.55;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const SkillWrapper = styled.div`
  @media (max-width: 500px) {
    button {
      margin-bottom: 15px;
    }
    a {
      display: block;
    }
  }
`;

const CharacterSkills = ({ char_skills, hidePlans = false, category, tier }) => {
  const { current } = char_skills ?? {};

  const [ skills ] = useApi(`/api/skills/category/${category.id}/tier/${tier.id}/skills`);

  let skill_categories = useMemo(() => {
    if(skills === undefined || skills === null){
      return [];
    }
    return Object.keys(skills);
  }, [skills])

  if (!skill_categories || !skills) {
    return <Spinner />
  }

  return (
    <>
      { !hidePlans && (
        <SkillWrapper>
          <CopyToSkillplan
            skillGroups={skills}
          />
        </SkillWrapper>
      )}
      <SkillSheet>
        { skill_categories.sort().map((category, key) => {
          let category_skills = skills[category];

          return (
            <SkillCategory key={key}>
              <h3>{category}</h3>
              { category_skills.sort((a, b) => a.name.localeCompare(b.name)).map((skill, key) => (
                <SkillRow
                  key={key}
                  {...skill}
                  current={current !== undefined ? current[skill.id] : null}
                  tier={tier}
                />
              ))}
            </SkillCategory>
          )
        })}
      </SkillSheet>
    </>
  )
}

export default CharacterSkills;
