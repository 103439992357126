import {useContext, useState} from "react";
import {AuthContext, EventContext} from "../../contexts";
import {apiCall, errorToaster} from "../../api";
import {Button} from "../../Components/Form";
import {Modal} from "../../Components/Modal";
import {Box} from "../../Components/Box";
import Spinner from "../../Components/Spinner";
import { toast } from "react-toastify";


const LinkTeamspeak = ({character_id}) => {
    const authContext = useContext(AuthContext);
    const eventContext = useContext(EventContext);

    const [open, isOpen] = useState(false);
    const [stage, setStage] = useState(0);
    const [key, setKey] = useState(undefined);
    const [sub, setSub] = useState(undefined);

    const finalizeAuth = (e) => {
        if(e !== undefined) {
            e.preventDefault();
        }
        errorToaster(apiCall(`/api/teamspeak/${character_id}/finalize_auth`, {
            method: "POST"
        }).then((res) => {
            isOpen(false);
            setKey(undefined);
            if(sub !== undefined){
                sub.unsubscribe();
                setSub(undefined);
            }
            return toast.success("Your teamspeak account has been linked!");
        }));
    };

    const deauth = (e) => {
        e.preventDefault();
        errorToaster(apiCall(`/api/teamspeak/${character_id}/deauth`, {
            method: "DELETE"
        }).then((res) => {
            setStage(1);
            getKey()
            return toast.success("Your old teamspeak account has been removed!");
        }));
    }

    const getKey = () => {
        apiCall(`/api/teamspeak/${character_id}/get_key`, {
            method: "GET",
        })
            .then((res) => {
                setKey(res)
                const char_sub = eventContext.subscriptions.create({channel: 'TeamspeakChannel'}, {
                    received(data){
                        if(data.event === 'ts3_link'){
                            finalizeAuth();
                        }
                    }
                })
                setSub(char_sub);
            })
    }

    const onOpen = (e) => {
        e.preventDefault();
        if (!authContext.teamspeak_linked) {
            setStage(1);
            getKey();
        }
        isOpen(true)
    }

    return (<>
            <Button
                onClick={onOpen}>{authContext.teamspeak_linked ? 'Re-link Teamspeak Account' : 'Link Teamspeak Account'}</Button>
            <Modal open={open} setOpen={isOpen}>
                {stage === 0 && (<Box>
                        <h2 style={{fontSize: "1.5em"}}>Relink Teamspeak account</h2>

                        <h3 style={{paddingTop: "15px", fontSize: "1.3em"}}>Only re-link if your old TS3 Identity is
                            lost or compromised</h3>

                        <p>To re-link your teamspeak account we first need to remove your old one, this involves banning
                            it from our TS3 server</p>

                        <p style={{paddingTop: "15px"}}>
                            If you still have access to the previous TS3 identity you linked to your waitlist account,
                            please go to the following guide to copy and reuse that identity
                        </p>
                        <a href={'https://support.teamspeak.com/hc/en-us/articles/360002712338-How-do-I-transfer-my-Unique-Identity-ID-to-another-PC'}>https://support.teamspeak.com/hc/en-us/articles/360002712338-How-do-I-transfer-my-Unique-Identity-ID-to-another-PC</a>

                        <h3 style={{paddingTop: "15px", fontSize: "1.3em"}}>If you still want to proceed with relinking
                            your TS3 account click the button below</h3>

                        <Button variant="success" onClick={deauth}>
                            Remove linked TS3 identity
                        </Button>
                    </Box>)}
                {stage === 1 && (<Box>
                        <h2 style={{fontSize: "1.5em"}}>Link Teamspeak account</h2>

                        <h3 style={{paddingTop: "15px", fontSize: "1.3em"}}>Click this link to join and auth on
                            Teamspeak: {key === undefined ? <Spinner/> :
                                <a href={`ts3server://hq.everookies.com${authContext?.current ? `?nickname=${authContext.current.name}` : ""}&token=${key}`}>Click
                                    here to auth on Teamspeak</a>}</h3>

                    <p style={{paddingTop: "15px", fontSize: "1.3em"}} >Using this link will connect you to teamspeak and automatically redeem the token listed below. If you have the Verified server group you can click Finalize below</p>
                        <h3 style={{paddingTop: "25px", fontSize: "1.1em"}}>Your auth token in the event the auto join
                            doesn&apos;t work
                            Teamspeak: {key === undefined ? <Spinner/> : key}</h3>

                        <p>Do not share this key with anyone. This key will last 5 minutes</p>

                        <p style={{paddingTop: "15px"}}>
                            The steps to redeem this key if the join link doesn&apos;t work differ depending on your Teamspeak client. Both clients require
                            you to be
                            connected to our server first
                        </p>
                        <ul style={{paddingTop: "15px"}}>
                            <li>For Teamspeak 5, right click the server name (Eve Rookies Incursions) at the top of your
                                client and
                                click Use Privilege Key
                            </li>
                            <li>For Teamspeak 3, go to the permissions dropdown at the top and click Use Privilege Key
                            </li>
                        </ul>

                        <h3 style={{paddingTop: "15px", fontSize: "1.3em"}}>Once you have redeemed the key and gotten
                            the verified
                            server group on our Teamspeak server click the Finalize button below</h3>

                        <Button variant="success" onClick={finalizeAuth}>
                            Finalize
                        </Button>
                    </Box>)}

            </Modal>
        </>);
};

export default LinkTeamspeak;
