import { useState } from "react";
import { Button } from "../../Components/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { toaster } from "../../api";

const CopyToSkillplan = ({skillGroups }) => {
  const [ copied, setCopied ] = useState(false);

  // Build a dictionary of skill IDs to Names
  let skills = {};
  Object.keys(skillGroups)?.forEach(category => {
    skillGroups[category].forEach(skill => {
      skills[skill.id] = {name: skill.name, level: skill.level};
    });
  });

  let text = '';
  Object.keys(skills)?.forEach(skillId => {
    let req = skills[skillId];
    let skillLevel = req.level;

    if (skillLevel === 1) {
      text += `${req.name} I\n`;
    }
    else if (skillLevel === 2) {
      text += `${req.name} II\n`;
    }
    else if (skillLevel === 3) {
      text += `${req.name} III\n`;
    }
    else if (skillLevel === 4) {
      text += `${req.name} IV\n`;
    }
    else if (skillLevel === 5) {
      text += `${req.name} V\n`;
    }
  });

  const exec = () => {
    setCopied(true);

    toaster(
      navigator.clipboard
      .writeText((text))
      .then((success) => "Copied to clipboard")
    )

    setTimeout(() => {
      setCopied(false);
    }, 0.5 * 1000);
  }

  return (
    <Button onClick={exec} disabled={copied}>
      Copy Skill Plan <FontAwesomeIcon fixedWidth icon={faCopy} />
    </Button>
  )
}

export default CopyToSkillplan;
