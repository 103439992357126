import { useMemo, useState } from "react";
import { apiCall, errorToaster } from "../../../../api";

import { Box } from "../../../../Components/Box";
import { Button, Buttons } from "../../../../Components/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FitDisplay } from "../../../../Components/FitDisplay";
import { Modal } from "../../../../Components/Modal"
import { Title } from "../../../../Components/Page";
import { SkillDisplay } from "../../../../Components/SkillDisplay";

async function approveFit(id) {
  return await apiCall("/api/waitlist/approve", {
    json: { id: id },
  });
}

async function rejectFit(id, review_comment) {
  return await apiCall("/api/waitlist/reject", {
    json: { id, review_comment },
  });
}

const FitModal = ({ fit, open, setOpen, ships }) => {
  const [ acceptPending, isAcceptPending ] = useState(false);
  const [ rejectPending, isRejectPending ] = useState(false);

  const handleApproveClick = () => {
    isAcceptPending(true);

    errorToaster(
      approveFit(fit.id).then(setOpen(false)).finally(isAcceptPending(false))
    );
  }

  const handleRejectClick = () => {
    var reason = prompt("Why is the fit being rejected? (Will be displayed to pilot)");
    if (reason) {
      isRejectPending(true);

      errorToaster(
        rejectFit(fit.id, reason).then(setOpen(false)).finally(isRejectPending(false))
      );
    }
  }

  const ship = useMemo(() => {
    if(fit === undefined || ships === undefined){
      return undefined;
    }
    return ships?.find(ship => ship.name === fit.hull.name)
  }, [fit, ships])

  if (!fit) {
    return null; // this modal cannot load until a fit is supplied
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      {open && (
        <Box>
          <Buttons>
            <Button variant="success" onClick={handleApproveClick} style={{ minWidth: '91px' }} disabled={acceptPending} pending={acceptPending}>
              { !acceptPending ? "Approve" : <FontAwesomeIcon fixedWidth icon={faSpinner} spin /> }
            </Button>
            <Button variant="danger" onClick={handleRejectClick} style={{ minWidth: '91px' }} disabled={rejectPending} pending={rejectPending}>
              { !acceptPending ? "Reject" : <FontAwesomeIcon fixedWidth icon={faSpinner} spin /> }
            </Button>
          </Buttons>

          <FitDisplay fit={fit} />
          { ship && fit.tags.includes("STARTER") && (
            <>
              <Title>Starter Skills</Title>
              <SkillDisplay characterId={fit.character.id} ship={ship} />
            </>
          )}
        </Box>
      )}
    </Modal>
  )
}

export default FitModal;
