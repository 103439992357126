import React, { useMemo } from "react";
// import { useLocation } from "react-router-dom";

import { AuthContext, } from "../../../contexts";
// import { errorToaster, openWindow, useApi } from "../../api";
import { usePageTitle } from "../../../Util/title";

// import AltCharacters from "./AltCharacters";
// import CharacterBadgeModal from "../FC/badges/CharacterBadgeModal";
// import BadgeIcon, { icons } from "../../Components/Badge";
// import styled from "styled-components";
//
// import { AccountBannedBanner } from "../FC/bans/AccountBanned";
// import { ActivitySummary } from "./ActivitySummary";
// import { Button, InputGroup, NavButton } from "../../Components/Form";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faBan, faClipboard, faGraduationCap, faPen, faPlane, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Title, Content } from "../../../Components/Page";
// import { PilotHistory } from "./PilotHistory";
import { Row, Col } from "react-awesome-styled-grid";
import { useParams } from "react-router-dom";
import Bosses from "./Bosses";
import Members from "./Members";
import Events from "./Events";
// import _ from "lodash";
// import UnlinkChar from "./UnlinkChar";
// import LinkTeamspeak from "./LinkTeamspeak";

// const FilterButtons = styled.span`
//   font-size: 0.75em;
//   margin-left: 2em;
//
//   a {
//     cursor: pointer;
//     padding: 0 0.2em;
//   }
// `;

// function PilotTags({ tags }) {
//   var tagImages = [];
//   _.forEach(tags, (tag) => {
//     if (tag in icons) {
//       tagImages.push(
//         <div key={tag} style={{ marginRight: "0.2em" }}>
//           <BadgeIcon type={tag} height="40px" />
//         </div>
//       );
//     }
//   });
//   return (
//     <div style={{ display: "flex", marginBottom: "0.6em", flexWrap: "wrap" }}>{tagImages}</div>
//   );
// }

export function FleetHistory() {
  const authContext = React.useContext(AuthContext);
  if (!authContext) {
    return (
      <Content>
        <b>Login Required!</b>
        <p>
          This page will let you see data about a specific fleet that was run
        </p>
      </Content>
    );
  }
  return <FleetDisplay authContext={authContext} />;
}

function FleetDisplay({ authContext }) {
  const url = useParams();
  const fleet_id = useMemo(() => {
    return url.fleetId
  }, [url])
  // const queryParams = new URLSearchParams(useLocation().search);

  // var characterId = queryParams.get("character_id") || authContext.current.id;
  // const [filter, setFilter] = React.useState(null);
  // const [basicInfo, refreshBasicInfo] = useApi(`/api/pilot/info?character_id=${characterId}`);
  // const [fleetHistory] = useApi(`/api/history/fleet?character_id=${characterId}`);
  // const [banHistory] = useApi(
  //   authContext.access["bans-manage"] ? `/api/v2/bans/${characterId}` : null
  // );
  // const [xupHistory] = useApi(`/api/history/xup?character_id=${characterId}`);
  // const [skillHistory] = useApi(`/api/history/skills?character_id=${characterId}`);
  // const [notes] = useApi(
  //   authContext.access["notes-view"] ? `/api/notes?character_id=${characterId}` : null
  // );


  usePageTitle("Fleet History");
  return (
    <>
      <Row>
        <Col xs={4} md={6}>
          <Title>
            History
            {/*<FilterButtons>*/}
            {/*  <a*/}
            {/*    onClick={(evt) => setFilter(null)}*/}
            {/*    style={{ marginRight: "0.5em" }}*/}
            {/*    title="Clear Filters"*/}
            {/*  >*/}
            {/*    <FontAwesomeIcon fixedWidth icon={faTimes} />*/}
            {/*  </a>*/}
            {/*  <a onClick={(evt) => setFilter("skill")} title="Skill History">*/}
            {/*    <FontAwesomeIcon fixedWidth icon={faGraduationCap} />*/}
            {/*  </a>*/}
            {/*  <a onClick={(evt) => setFilter("fit")} title="X-UP History">*/}
            {/*    <FontAwesomeIcon fixedWidth icon={faPen} />*/}
            {/*  </a>*/}
            {/*  <a onClick={(evt) => setFilter("fleet")} title="Fleet History">*/}
            {/*    <FontAwesomeIcon fixedWidth icon={faPlane} />*/}
            {/*  </a>*/}
            {/*  {authContext.access["notes-view"] && (*/}
            {/*    <a onClick={(evt) => setFilter("note")} title="Pilot Notes">*/}
            {/*      <FontAwesomeIcon fixedWidth icon={faClipboard} />*/}
            {/*    </a>*/}
            {/*  )}*/}
            {/*  {authContext.access["bans-manage"] && (*/}
            {/*    <a onClick={(evt) => setFilter("ban")} title="Character Ban History">*/}
            {/*      <FontAwesomeIcon fixedWidth icon={faBan} />*/}
            {/*    </a>*/}
            {/*  )}*/}
            {/*</FilterButtons>*/}
          </Title>

          <Events fleet_id={fleet_id} />
        </Col>
        <Col xs={4} md={2}>
          <Bosses fleet_id={fleet_id} />
          <Members fleet_id={fleet_id} />


          {/*<Title>Time in fleet</Title>*/}
          {/*<ActivitySummary summary={fleetHistory && fleetHistory.summary} />*/}


        </Col>
      </Row>
    </>
  );
}
