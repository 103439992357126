import { Header } from "../../../Components/Page";
import { useApi } from "../../../api";
import { useMemo } from "react";
import Table from "../../../Components/DataTable";
import styled from "styled-components";
import A from "../../../Components/A";

const DOM = styled.div`
  margin-bottom: 50px;
`;


const RecentFleets = () => {
  const [ recentFleets ] = useApi('/api/v2/fleets/history')

  const fleets = useMemo(() => {
    let _fleets = {}
    if(recentFleets === null){
      return _fleets;
    }
    recentFleets.fleets.forEach(fleet => {
      let id = fleet.fleet_id;
      _fleets[id] = {
        fleet_id: id,
        bosses: fleet.bosses.map((boss) => {return `${boss.name} (${(boss.duration/3600).toFixed(2)})`}),
        total_time: fleet.fleet_time,
        start_time: fleet.start_time
      }
    })
    return Object.values(_fleets);
  }, [recentFleets])

  return (
          <DOM>
            <Header>
              <h2>Recent Fleets</h2>
            </Header>
            <Table
              columns={[
                { name: "Fleet ID", selector: (r) => <A href={`/fc/fleets/${r?.fleet_id}/history`}>{r?.fleet_id}</A> },
                { name: "Bosses", selector: (r) => r?.bosses.join(', ') },
                { name: "Fleet Time", selector: (r) => (r?.total_time / 3600).toFixed(2) },
                { name: "Start Time", selector: (r) => r?.start_time }
              ]}
              data={fleets ?? []}
              progressPending={!fleets}
              pagination={false}
            />
          </DOM>
  )
}

export default RecentFleets;
