import { useContext, useEffect } from "react";
import { useApi } from '../../../api';
import { useParams } from 'react-router-dom';
import { FireNotificationApi } from '../../../Components/Event';


import FleetSettings from './Settings';
import FleetComps from './FleetComps';
import Waitlist from './Waitlist';
import Payouts from './Payouts';
import { AuthContext, EventContext } from "../../../contexts";
import MissingScopesBanner from "../fleets/MissingScopesBanner";

const FleetsManagementPage = () => {
  const eventContext = useContext(EventContext);
  const authContext = useContext(AuthContext);
  const url = useParams();
  const [ xup, refresh ] = useApi(`/api/v2/fleets/${url?.fleetId}/waitlist`);
  const [ settings, settingsRefresh ] = useApi(`/api/v2/fleets/${url?.fleetId}`);
  const [ pilots, pilotRefresh ] = useApi(`/api/v2/fleets/${url?.fleetId}/comp`);
  const [ payouts, refreshPayouts] = useApi(`/api/v2/fleets/${url?.fleetId}/payouts`);
  const [ has_scopes ] = useApi(`/api/auth/${authContext?.current?.id}/has_fc_scopes`)


  useEffect(() => {
    if (!eventContext) return undefined;


    const waitlist_sub = eventContext.subscriptions.create({channel: 'WaitlistChannel'}, {
      received(data){
        refresh(data);
      }
    })

    const fc_sub = eventContext.subscriptions.create({channel: 'FcChannel'}, {
      received(data){
        if(data.event === 'notification'){
          let event_data = data?.data;
          if (!event_data) return;

          FireNotificationApi({
            title: event_data?.title,
            body: event_data.message,
          });
        }
      }
    })

    return () => {
      waitlist_sub.unsubscribe();
      fc_sub.unsubscribe();
    }
  }, [eventContext, refresh])

  if(settings === null) {
    return (
      <>
        <h1>Fleet not found</h1>
      </>
    )
  }

  return (
    <>
      {has_scopes === 'false' && (
        <MissingScopesBanner/>
      )}
      <FleetSettings fleetId={url?.fleetId} xups={xup?.waitlist} settings={settings} settingsRefresh={settingsRefresh} />
      {pilots === null ? (
        <h1>Cannot load fleet comp</h1>
      ) : (
        <FleetComps fleetId={url?.fleetId} pilots={pilots} pilotRefresh={pilotRefresh} />
      )}
      {payouts === null ? (
        <h1>Cannot load fleet payouts</h1>
      ) : (
        <Payouts fleetId={url?.fleetId} payouts={payouts} refreshPayouts={refreshPayouts} />
      )}

      <Waitlist xup={xup} settings={settings} />
    </>
  )
}

export default FleetsManagementPage;
