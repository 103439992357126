import { useApi } from "../../../api";
import Spinner from "../../../Components/Spinner";
import {
  PlusCircleIcon,
  PencilSquareIcon,
  StopCircleIcon,
  FingerPrintIcon,
  UserPlusIcon, UserMinusIcon, ArrowsUpDownIcon, ArrowsRightLeftIcon,
} from "@heroicons/react/20/solid";
import { useContext, useMemo } from "react";
import A from "../../../Components/A";
import { ThemeContext } from "styled-components";

const Event = ({theme, event}) => {

  const icon = useMemo(() => {
    switch (event.type) {
      case 'update':
        return <PencilSquareIcon className={`h-5 w-5 ${theme.colors.text_tailwind}`} aria-hidden="true" />
      case 'creation':
        return <PlusCircleIcon className={`h-5 w-5 ${theme.colors.text_tailwind}`} aria-hidden="true" />
      case 'closure':
        return <StopCircleIcon className={`h-5 w-5 ${theme.colors.text_tailwind}`} aria-hidden="true" />
      case 'boss_change':
        return <FingerPrintIcon className={`h-5 w-5 ${theme.colors.text_tailwind}`} aria-hidden="true" />
      case 'rejoin':
        return <ArrowsRightLeftIcon className={`h-5 w-5 ${theme.colors.text_tailwind}`} aria-hidden="true" />
      case 'multi-join':
      case 'join':
      case 'add':
      case 'multi-add':
        return <UserPlusIcon className={`h-5 w-5 ${theme.colors.text_tailwind}`} aria-hidden="true" />
      case 'leave':
      case 'remove':
        return <UserMinusIcon className={`h-5 w-5 ${theme.colors.text_tailwind}`} aria-hidden="true" />
      case 'reship':
        return <ArrowsUpDownIcon className={`h-5 w-5 ${theme.colors.text_tailwind}`} aria-hidden="true" />
      default:
        return <UserPlusIcon className={`h-5 w-5 ${theme.colors.text_tailwind}`} aria-hidden="true" />
    }
  }, [event, theme])

  const content = useMemo(() => {
    switch (event.type) {
      case 'update':
        return <Update event={event} theme={theme} />
      case 'multi-join':
        return <MultiJoin event={event} theme={theme} />
      case 'creation':
        return <Creation event={event} theme={theme} />
      case 'closure':
        return <Closure event={event} theme={theme} />
      case 'boss_change':
        return <BossChange event={event} theme={theme} />
      case 'join':
        return <Join event={event} theme={theme} />
      case 'leave':
        return <Leave event={event} theme={theme} />
      case 'rejoin':
        return <Rejoin event={event} theme={theme} />
      case 'add':
        return <Add event={event} theme={theme} />
      case 'multi-add':
        return <MultiAdd event={event} theme={theme} />
      case 'remove':
        return <Remove event={event} theme={theme} />
      case 'reship':
        return <Reship event={event} theme={theme} />
      default:
        return <UserPlusIcon className={`h-5 w-5 ${theme.colors.text_tailwind}`} aria-hidden="true" />
    }
  }, [event, theme])

  return (
    <>
      <div>
        <div className="relative px-1">
          <div
            className={`flex h-8 w-8 items-center justify-center rounded-full ${theme.colors.secondary.tailwind_bg} ring-8 ${theme.colors.bg_ring}`}>
            {icon}
          </div>
        </div>
      </div>
      <div className="min-w-0 flex-1 py-1.5">
        {content}
      </div>
    </>
  );
}
const Rejoin = ({theme, event}) => {
  return (
    <>
      <div className={`text-sm ${theme.colors.text_tailwind}\`}`}>
        {` ${event.formatted_time} - `}
        <A href={`/fc/search?query=${event.main}`} className="font-medium">
          {event.main}
        </A>
        {" "}
        re-joined the fleet after a {(event.data[1] / 3600).toFixed(2)} hour break {" "}
        {
          event.main !== event.character ? (
            <>
              as{" "}
              <A href={`/fc/search?query=${event.character}`} className="font-medium">
                {event.character}
              </A>{" "}
            </>
          ) : (<></>)
        }
        in a {event.data[0]}
      </div>
    </>
  );
}
const Leave = ({ theme, event }) => {
  return (
    <>
      <div className={`text-sm ${theme.colors.text_tailwind}\`}`}>
        {` ${event.formatted_time} - `}
        <A href={`/fc/search?query=${event.main}`} className="font-medium">
          {event.main}
        </A>
        {" "}
        {
          event.main !== event.character ? (
            <>
              as{" "}
              <A href={`/fc/search?query=${event.character}`} className="font-medium">
                {event.character}
              </A>{" "}
            </>
          ) : (<></>)
        } {" "}
        left the fleet
      </div>
    </>
  );
};
const BossChange = ({ theme, event }) => {
  return (
    <>
      <div className={`text-sm ${theme.colors.text_tailwind}\`}`}>
        {` ${event.formatted_time} - `}
        <A href={`/fc/search?query=${event.main}`} className="font-medium">
        {event.main}
        </A>
        {" "}
        took over fleet boss {" "}
        {
          event.main !== event.character ? (
            <>
              as{" "}
              <A href={`/fc/search?query=${event.character}`} className="font-medium">
                {event.character}
              </A>{" "}
            </>
          ) : (<></>)
        }
      </div>
    </>
  );
};
const Closure = ({ theme, event }) => {
  return (
    <>
      <div className={`text-sm ${theme.colors.text_tailwind}\`}`}>
        {` ${event.formatted_time} - `}
        Fleet was closed
      </div>
    </>
  );
};
const Creation = ({ theme, event }) => {
  return (
    <>
      <div className={`text-sm ${theme.colors.text_tailwind}\`}`}>
        {` ${event.formatted_time} - `}
        <A href={`/fc/search?query=${event.main}`} className="font-medium">
          {event.main}
        </A>
        {" "}
        started the fleet{" "}
        {
          event.main !== event.character ? (
            <>
              as{" "}
              <A href={`/fc/search?query=${event.character}`} className="font-medium">
                {event.character}
              </A>{" "}
            </>
          ) : (<></>)
        }
        in {event.data.system}
        {" "}
        with an on gird max of {event.data.on_grid.to}{", "}
        visibility {event.data.visible.toString()} {" "}
        and site type of {event.data.site_type}
      </div>
    </>
  );
}
const Update = ({ theme, event }) => {
  return (
    <>
      <div className={`text-sm ${theme.colors.text_tailwind}\`}`}>
        {` ${event.formatted_time} - `}
        Fleet settings editeded -{" "}
        {event.data[0]} was changed from {event.data[1].toString()} to {event.data[2].toString()}
      </div>
    </>
  );
};
const MultiJoin = ({ theme, event }) => {

  const tooltip = useMemo(() => {
    let text = "";
    for (let i = 0; i < event.data.chars.length; i++) {
      if (i > 0) {
        text += ", ";
      }
      text += `${event.data.chars[i]} - ${event.data.hulls[i]}`;
    }
    return text;
  }, [event]);

  return (
    <>
      <div className={`text-sm ${theme.colors.text_tailwind}\`}`} data-tooltip-id="tip" data-tooltip-content={tooltip}>
        {` ${event.formatted_time} - `}
        <A href={`/fc/search?query=${event.main}`} className="font-medium">
          {event.main}
        </A>
        {" "}
        joined the fleet with {event.data.chars.length} characters
      </div>
    </>
  );
};
const Join = ({ theme, event }) => {
  return (
    <>
      <div className={`text-sm ${theme.colors.text_tailwind}\`}`}>
        {` ${event.formatted_time} - `}
        <A href={`/fc/search?query=${event.main}`} className="font-medium">
          {event.main}
        </A>
        {" "}
        joined the fleet {" "}
        {
          event.main !== event.character ? (
            <>
              as{" "}
              <A href={`/fc/search?query=${event.character}`} className="font-medium">
                {event.character}
              </A>{" "}
            </>
          ) : (<></>)
        }
        in a {event.data[0]}
      </div>
    </>
  );
};
const MultiAdd = ({ theme, event }) => {

  const tooltip = useMemo(() => {
    let text = "";
    for (let i = 0; i < event.data.chars.length; i++) {
      if (i > 0) {
        text += ", ";
      }
      text += `${event.data.chars[i]} - ${event.data.hulls[i]}`;
    }
    return text;
  }, [event]);

  return (
    <>
      <div className={`text-sm ${theme.colors.text_tailwind}\`}`} data-tooltip-id="tip" data-tooltip-content={tooltip}>
        {` ${event.formatted_time} - `}
        <A href={`/fc/search?query=${event.main}`} className="font-medium">
          {event.main}
        </A>
        {" "}
        added {event.data.chars.length} characters to the fleet
      </div>
    </>
  );
};
const Add = ({ theme, event }) => {
  return (
    <>
      <div className={`text-sm ${theme.colors.text_tailwind}\`}`}>
        {` ${event.formatted_time} - `}
        <A href={`/fc/search?query=${event.main}`} className="font-medium">
          {event.main}
        </A>
        {" "}
        added {' '}
        <A href={`/fc/search?query=${event.character}`} className="font-medium">
          {event.character}
        </A>{" "}
        to the fleet {" "}
        in a {event.data[0]}
      </div>
    </>
  );
};
const Remove = ({ theme, event }) => {
  return (
    <>
      <div className={`text-sm ${theme.colors.text_tailwind}\`}`}>
        {` ${event.formatted_time} - `}
        <A href={`/fc/search?query=${event.main}`} className="font-medium">
          {event.main}
        </A>
        {" "}
        removed {' '}
        <A href={`/fc/search?query=${event.character}`} className="font-medium">
          {event.character}
        </A>{" "}
        from the fleet {" "}
      </div>
    </>
  );
};
const Reship = ({ theme, event }) => {
  return (
    <>
      <div className={`text-sm ${theme.colors.text_tailwind}\`}`}>
        {` ${event.formatted_time} - `}
        <A href={`/fc/search?query=${event.main}`} className="font-medium">
          {event.main}
        </A>
        {" "}
        {
          event.main !== event.character ? (
            <>
              as{" "}
              <A href={`/fc/search?query=${event.character}`} className="font-medium">
                {event.character}
              </A>{" "}
            </>
          ) : (<></>)
        }
        re-shiped from a {event.data[0]} to a {event.data[1]}
      </div>
    </>
  );
};

const Events = ({ fleet_id }) => {
  const theme = useContext(ThemeContext);
  const [events] = useApi(`/api/v2/fleets/${fleet_id}/history/events`);
  return (
    <div>
      {!events ? (
        <Spinner />
      ) : events?.length > 0 ? (
        <div className="flow-root pt-8">
          <ul className="-mb-8">
            {events.map((event, activityItemIdx) => (
              <li key={event.time}>
                <div className="relative pb-8">
                  {activityItemIdx !== events.length - 1 ? (
                    <span className={`absolute left-5 top-5 -ml-px h-full w-0.5 ${theme.colors.text_tailwind_bg}`} aria-hidden="true" />
                  ) : null}
                  <div className="relative flex items-start space-x-3">
                    <Event theme={theme} event={event}/>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <em style={{ fontSize: "smaller" }}>Fleet had no logged events, this is a bug, please alert the Dev</em>
      )}

    </div>

  );
};


export default Events;
