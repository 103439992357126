import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";

const ShipsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;

  div {
    padding-bottom: 10px;
    color: ${(props) => props.theme.colors.secondary};
    text-align: center;
    width: 90px;

    img:first-of-type {
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 5px;
      transition: ease-in-out 0.3s;
    }

    @media (max-width: 1000px) {
      font-size: 15px;

      img {
        max-width: 32px;
      }
    }

    img:last-of-type {

    }

    &:hover, &.active {
      cursor: pointer;

      img:first-of-type {
        box-shadow: 0px 0px 6px 0px ${(props) => props.theme.colors.accent};
      }
    }
  }
`;

const Tabs = ({ selectedHull, categories }) => {
  const queryParams = new URLSearchParams(useLocation().search);
  const navigate = useNavigate();

  const onClick = (hull) => {
    queryParams.set("hull", hull);
    queryParams.delete('mastery');
    navigate({
      search: queryParams.toString()
    })
  }

  return categories ? (
    <ShipsContainer>
      { categories && categories.map((category, key) => (
        <div className={selectedHull === category.name ? 'active' : null} key={key} onClick={e => onClick(category.name)}>
          <img src={`https://images.evetech.net/types/${category.hull_id}/icon?size=64`} alt={category.name} />
          {category.name}
        </div>
      ))}
    </ShipsContainer>
  ) : null
}

export default Tabs;
