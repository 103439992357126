import React, { useEffect } from "react";
import { apiCall, errorToaster, toaster, useApi } from "../../../api";
import { Box } from "../../../Components/Box";
import { CharacterName } from "../../../Components/EntityLinks";
import { Button, CenteredButtons } from "../../../Components/Form";
import { Modal } from "../../../Components/Modal";
import { Title } from "../../../Components/Page";
import styled from "styled-components";
import BadgeIcon from "../../../Components/Badge";

const Wrapper = styled.div`
    margin-left: 40px;
    margin-bottom: 5px;

    &:first-of-type {
        min-height: 50px;
    }

    &:nth-of-type(2) {
        min-height: 100px;
    }

    @media (min-width: 400px) {
        min-width: 310px;
    }

    @media (max-width: 451px) {
        margin-left: 3px;

        span {
            display: block;
        }

        br {
            display: none;
        }
    }
`;

async function assignBadge(badgeId, characterId, ship_id = null) {
  return await apiCall(`/api/badges/${badgeId}/members`, {
    method: "POST",
    json: { id: characterId, ship_id },
  });
}

async function revokeBadge(badgeId, characterId, ship_id = null) {
  return await apiCall(`/api/badges/${badgeId}/members/${characterId}`, {
    method: "DELETE",
    json: {ship_id}
  });
}

const CharacterBadgeModal = ({ character, refreshData }) => {
  const [isOpen, setOpen] = React.useState();
  return (
    <>
      <Button onClick={() => setOpen(true)}>Badges</Button>
      {isOpen && <BadgeModal {...{ character, isOpen, setOpen, refreshData }} />}
    </>
  );
};

export default CharacterBadgeModal;

const BadgeModal = ({ character, isOpen, setOpen, refreshData }) => {
  const [avaliableBadges, refreshBadges] = useApi("/api/badges"); // Get data from the API
  const [badges, setBadges] = React.useState(undefined); // This data includes bools checked, default
  const [pending, isPending] = React.useState(false);

  useEffect(() => {
    if (!avaliableBadges) return undefined; // Make the call ONLY once when the badge options are known

    errorToaster(
      apiCall(`/api/pilot/info?character_id=${character?.id}`, {}).then((res) => {
        if (!res.badges) return; // can't do anything if the tags array is undefined

        const badges = res.badges;
        const b = avaliableBadges;
        for (let i = 0; i < b?.length; i++) {
          b[i].default = badges.some(el => el.id === b[i].id);
          b[i].checked = badges.some(el => el.id === b[i].id);
          if(b[i].ship_types.length !== 0){
            let ship_types = b[i].ship_types;
            for(let j = 0; j < ship_types.length; j++) {
              ship_types[j].default = badges.some(el => el.id === b[i].id && el.ship?.id === ship_types[j].id);
              ship_types[j].checked = badges.some(el => el.id === b[i].id && el.ship?.id === ship_types[j].id);
            }
          }
        }

        setBadges(b ?? []);
      }),
    );
  }, [avaliableBadges, character?.id]);

  const onSubmit = () => {
    isPending(true);

    let promises = [];
    badges.forEach((badge) => {
      // These badges are being revoked
      if (badge.ship_types.length === 0 && badge.default && !badge.checked) {
        promises.push(
          new Promise((resolve) => {
            toaster(revokeBadge(badge.id, character.id)).then(resolve);
          }),
        );
      } else {
        badge.ship_types.forEach((ship_type) => {
          if(ship_type.default && !ship_type.checked) {
            promises.push(
              new Promise((resolve) => {
                toaster(revokeBadge(badge.id, character.id, ship_type.id)).then(resolve);
              }),
            );
          }
        })
      }
    });

    Promise.all(promises).then(() => {
      // All badges revoked, now we need to assign new badges
      let promises = [];
      badges.forEach((badge) => {
        if (badge.ship_types.length === 0 && !badge.default && badge.checked) {
          promises.push(
            new Promise((resolve) => {
              toaster(assignBadge(badge.id, character.id)).then(resolve);
            }),
          );
        } else {
          badge.ship_types.forEach((ship_type) => {
            if(!ship_type.default && ship_type.checked) {
              promises.push(
                new Promise((resolve) => {
                  toaster(assignBadge(badge.id, character.id, ship_type.id)).then(resolve);
                }),
              );
            }
          })
        }
      });

      // When all revocations and assignments are completed
      Promise.all(promises).then(() => {
        refreshBadges();
        isPending(false);
        if (refreshData) refreshData();
      });
    });
  };

  const onCheck = (evt, i, badge) => {
    badge.checked = evt.target.checked;
    let b = badges;
    b[i] = badge;
    setBadges([...b]);
  };

  const onShipCheck = (evt, i, j, badge, ship) => {
    ship.checked = evt.target.checked;
    let b = badges;
    let s = b[i].ship_types;
    s[j] = ship;
    b[i].ship_types = [...s];
    setBadges([...b]);
  };

  return (
    <Modal open={isOpen} setOpen={setOpen}>
      <Box>
        <Title style={{ marginBottom: "5px" }}>
          <CharacterName {...character} avatarSize={32} noLink />
        </Title>

        <Wrapper>
          {!badges ? (
            <small style={{ fontStyle: "italic", fontSize: "smaller" }}>Loading...</small>
          ) : (
            <>
              <p>Current Badges:</p>
              {badges.map((badge, key) => {
                return badge.default ? (
                  <span style={{ marginRight: "5px", display: "inline-block" }} key={key}>
                    <BadgeIcon type={badge.name} key={key} />
                  </span>
                ) : null;
              })}
            </>
          )}
        </Wrapper>

        <Wrapper>
          {badges?.map((badge, key) => {
            if(badge.ship_types.length > 0){
              return null;
            }
            return (
              <span key={key}>
                {key % 3 ? null : <br />}
                <label
                  style={{ marginRight: "20px", marginBottom: "15px", display: "inline-block" }}
                  htmlFor={key}
                >
                  <input
                    id={key}
                    type="checkbox"
                    checked={badge.checked}
                    onChange={(evt) => onCheck(evt, key, badge)}
                  />
                  {badge.name}
                </label>
              </span>
            );
          })}
        </Wrapper>

        <Wrapper>
          {badges?.map((badge, key) => {
            if (badge.ship_types.length === 0) {
              return null;
            }
            console.log(badge);
            return (
              <div key={key}>
                <p>{badge.name} Ships:</p>
                {badge.ship_types.map((ship, ship_key) => {
                  return (
                    <span key={'ship' + badge.id + ship_key}>
                      {ship_key % 3 ? null : <br />}
                      <label
                        style={{ marginRight: "20px", marginBottom: "15px", display: "inline-block" }}
                        htmlFor={'ship' + badge.id + ship_key}
                      >
                        <input
                          id={'ship' + badge.id + ship_key}
                          type="checkbox"
                          checked={ship.checked}
                          onChange={(evt) => onShipCheck(evt, key, ship_key, badge, ship)}
                        />
                        {ship.name}
                      </label>
                    </span>
                  );
                })}
              </div>
            );
          })}
        </Wrapper>

        <CenteredButtons>
          <Button variant="success" onClick={onSubmit} disabled={pending}>
            Confirm
          </Button>
          <Button onClick={() => setOpen(false)}>Close</Button>
        </CenteredButtons>
      </Box>
    </Modal>
  );
};
