import { Header } from "../../Components/Page";
import { usePageTitle } from "../../Util/title";
import { useApi } from "../../api";
import { useMemo } from "react";

const statuses = {
  offline: 'text-gray-500 bg-gray-100/10',
  green: 'text-green-400 bg-green-400/10',
  red: 'text-rose-400 bg-rose-400/10',
  yellow: 'text-yellow-400 bg-yellow-400/10'
}
const endpoints = [
  {
    id: 1,
    endpointGroup: 'ESI Fleets',
    route: '/characters/{character_id}/fleet/',
    method: 'get',
    description: 'Issues with this endpoint prevent any fleet functions from working',
  },
  {
    id: 2,
    endpointGroup: 'ESI Fleets',
    route: '/fleets/{fleet_id}/',
    method: 'put',
    description: 'Issues with this endpoint prevent fleet creation from working if you choose the set default MOTD option',
  },
  {
    id: 3,
    endpointGroup: 'ESI Fleets',
    route: '/fleets/{fleet_id}/wings/',
    method: 'get',
    description: 'Issues with this endpoint prevent any fleet functions from working',
  },
  {
    id: 4,
    endpointGroup: 'ESI Fleets',
    route: '/fleets/{fleet_id}/wings/',
    method: 'post',
    description: 'Issues with this endpoint prevent fleet creation from working',
  },
  {
    id: 5,
    endpointGroup: 'ESI Fleets',
    route: '/fleets/{fleet_id}/wings/{wing_id}/',
    method: 'put',
    description: 'Issues with this endpoint prevent fleet creation from working',
  },
  {
    id: 6,
    endpointGroup: 'ESI Fleets',
    route: '/fleets/{fleet_id}/wings/{wing_id}/',
    method: 'delete',
    description: 'Issues with this endpoint prevent fleet creation from working if you choose the default squads option',
  },
  {
    id: 7,
    endpointGroup: 'ESI Fleets',
    route: '/fleets/{fleet_id}/wings/{wing_id}/squads/',
    method: 'post',
    description: 'Issues with this endpoint prevent fleet creation from working and will also cause issues inviting boxers if no free boxer squad is available',
  },
  {
    id: 8,
    endpointGroup: 'ESI Fleets',
    route: '/fleets/{fleet_id}/squads/{squad_id}/',
    method: 'put',
    description: 'Issues with this endpoint prevent fleet creation from working and will also cause issues inviting boxers if no free boxer squad is available',
  },
  {
    id: 9,
    endpointGroup: 'ESI Fleets',
    route: '/fleets/{fleet_id}/members/{member_id}/',
    method: 'delete',
    description: 'Issues with this endpoint will cause issues with fleet closing/deletion',
  },
  {
    id: 10,
    endpointGroup: 'ESI Fleets',
    route: '/fleets/{fleet_id}/members/',
    method: 'get',
    description: 'Issues with this endpoint will prevent all fleet functions from working',
  },
  {
    id: 11,
    endpointGroup: 'ESI Fleets',
    route: '/fleets/{fleet_id}/members/',
    method: 'post',
    description: 'Issues with this endpoint will prevent fleet invites from working',
  },
  {
    id: 12,
    endpointGroup: 'ESI UI',
    route: '/ui/openwindow/information/',
    method: 'post',
    description: 'Issues with this endpoint only prevent the website from opening info windows such as pilot info in-game for you',
  },
  {
    id: 13,
    endpointGroup: 'ESI UI',
    route: '/ui/openwindow/contract/',
    method: 'post',
    description: 'Issues with this endpoint only prevent the website from opening contract windows in-game for the user',
  },
  {
    id: 14,
    endpointGroup: 'ESI UI',
    route: '/ui/openwindow/marketdetails/',
    method: 'post',
    description: 'Issues with this endpoint only prevent the website from opening market info windows in-game for the user',
  },
  {
    id: 15,
    endpointGroup: 'ESI Characters',
    route: '/characters/{character_id}/',
    method: 'get',
    description: 'Issues with this endpoint will prevent fleet stats from working and character/account bans from being able to be issued',
  },
  {
    id: 16,
    endpointGroup: 'ESI Characters',
    route: '/characters/{character_id}/search/',
    method: 'get',
    description: 'Issues with this endpoint will prevent ESI search related functions such as pilot search from working',
  },
  {
    id: 17,
    endpointGroup: 'ESI Characters',
    route: '/characters/{character_id}/implants/',
    method: 'get',
    description: 'Issues with this endpoint will prevent anything needing implant data, such as X-ups, from working',
  },
  {
    id: 18,
    endpointGroup: 'ESI Characters',
    route: '/characters/{character_id}/skills/',
    method: 'get',
    description: 'Issues with this endpoint will prevent anything needing skills data, such as X-ups, from working',
  },
  {
    id: 19,
    endpointGroup: 'ESI Characters',
    route: '/characters/affiliation/',
    method: 'post',
    description: 'Issues with this endpoint will logins and automatic affiliation updates from working',
  },
  {
    id: 20,
    endpointGroup: 'ESI Characters',
    route: '/corporations/{corporation_id}/',
    method: 'get',
    description: 'Issues with this endpoint will logins and automatic affiliation updates from working as well as prevent corporation bans from being able to be issued',
  },
  {
    id: 21,
    endpointGroup: 'ESI Characters',
    route: '/alliances/{alliance_id}/',
    method: 'get',
    description: 'Issues with this endpoint will logins and automatic affiliation updates from working as well as prevent alliance bans from being able to be issued',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const StatusPage = () => {
  const [endpoint_status] = useApi("/api/status");
  usePageTitle("ESI Endpoint Status");

  const completedStatuses = useMemo(() => {
    const finished = [];
    if(endpoint_status === null){
      return finished;
    }
    endpoints.forEach((endpoint) => {
      const status = endpoint_status.filter(eps => (eps.route === endpoint.route && eps.method === endpoint.method))[0];
      if(status === undefined || status === null){
        endpoint.status = 'offline'
      } else {
        endpoint.status = status.status
      }
      finished.push(endpoint)
    })
    return finished;
  }, [endpoints, endpoint_status])

  return (
    <>
      <Header>
        <h1>ESI Endpoint Status Info</h1>
      </Header>
      <ul role="list" className="divide-y divide-white/5">
        {completedStatuses.map((endpoint) => (
          <li key={endpoint.id} className="relative flex items-center space-x-4 py-4">
            <div className="min-w-0 flex-auto">
              <div className="flex items-center gap-x-3">
                <div className={classNames(statuses[endpoint.status], 'flex-none rounded-full p-1')}>
                  <div className="h-2 w-2 rounded-full bg-current" />
                </div>
                <h2 className="min-w-0 text-sm font-semibold leading-6 text-white">
                  <div className="flex gap-x-2">
                    <span className="truncate">{endpoint.endpointGroup}</span>
                    <span className="text-gray-400">/</span>
                    <span className="whitespace-nowrap">{endpoint.route} - {endpoint.method.toUpperCase()}</span>
                    <span className="absolute inset-0" />
                  </div>
                </h2>
              </div>
              <div className="mt-3 flex flex-col justify-items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                {endpoint.status === 'red' && (
                  <p>Endpoint is reporting as red. Function is likely unavailable</p>
                )}
                {endpoint.status === 'yellow' && (
                  <p>Endpoint is reporting as yellow. Function is likely unreliable or slow</p>
                )}
                {endpoint.status === 'offline' && (
                  <p>Endpoint status was unable to be retrieved</p>
                )}
                {endpoint.status !== 'green' && (
                  <p>{endpoint.description}</p>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export default StatusPage;
