import { InfoNote } from "../../Components/NoteBox";
import { Highlight } from "../../Components/Form";
import { Copyable } from "../../Components/Copy";
import { AuthContext } from "../../contexts";
import { useContext } from "react";
import {
  CellHead,
  SmallCellHead,
  Table,
  TableHead,
  Row,
  TableBody,
  Cell,
} from "../../Components/Table";
import { errorToaster, openWindow } from "../../api";

export function ImplantTable({ type }) {
  const authContext = useContext(AuthContext);

  let implants;
  if (type === "Amulet") {
    implants = [
      "High-grade Amulet Alpha",
      "High-grade Amulet Beta",
      "High-grade Amulet Gamma",
      "High-grade Amulet Delta",
      "High-grade Amulet Epsilon",
    ];
  } else {
    implants = [
      "High-grade Ascendancy Alpha",
      "High-grade Ascendancy Beta",
      "High-grade Ascendancy Gamma",
      "High-grade Ascendancy Delta",
      "High-grade Ascendancy Epsilon",
    ];
  }
  return (
    <>
      <InfoNote>
        {type === "Amulet"
          ? "Amulet tagged fits require at least Amulet 1 - 5 to be flown."
          : "Required for Elite badge on non implant specific ships."}
      </InfoNote>

      <Table style={{ width: "100%" }}>
        <TableHead>
          <Row>
            <SmallCellHead></SmallCellHead>
            <CellHead>DEFAULT</CellHead>
            <CellHead>ALTERNATIVE (not required)</CellHead>
          </Row>
        </TableHead>
        <TableBody>
          {implants.map((currentValue, index) => (
            <ImplantAllRow
              key={index}
              slot={index + 1}
              implant={currentValue}
              auth={authContext}
            />
          ))}

          <Row>
            <Cell>
              <b>Slot 6</b>
            </Cell>
            <Cell>
              <CopyImplantText item={"WS-618"} auth={authContext} /> increased warp speed.
            </Cell>
            {type === "Amulet" ? (
              <Cell></Cell>
            ) : (
              <Cell>
                <CopyImplantText item={"High-grade Ascendancy Omega"} auth={authContext} /> if you
                have too much isk, increased warp speed.
              </Cell>
            )}
          </Row>

          <HardWires auth={authContext} />
        </TableBody>
      </Table>
    </>
  );
}

function ImplantAllRow({ slot, implant, auth }) {
  return (
    <Row>
      <Cell>
        <b>Slot {slot}</b>
      </Cell>
      <Cell>
        <CopyImplantText item={implant} auth={auth} />
      </Cell>

      <Cell></Cell>
    </Row>
  );
}

function CopyImplantText({ item, auth }) {
  return (
    <Highlight
      onClick={(evt) => {
        if(auth) {
          errorToaster(openWindow(null, auth.current.id, 'marketdetails', item))
        } else {
          Copyable(item);
        }
      }}
    >
      {item}
    </Highlight>
  );
}

function HardWires({ auth }) {
  return (
    <>
      <Row>
        <Cell>
          <b>Slot 7</b>
        </Cell>
        <Cell>
          <CopyImplantText item={"Ogdin's Eye"} auth={auth} /> increased tracking.
        </Cell>

        <Cell>
          <CopyImplantText item={"MR-706"} auth={auth} /> equal to Ogdin&apos;s. <br />
          <CopyImplantText item={"RA-706"} auth={auth} /> reps will use less cap, for
          <b> logi only pilots.</b>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <b>Slot 8</b>
        </Cell>
        <Cell>
          <CopyImplantText item={"EM-806"} auth={auth} /> increased capacitor.
        </Cell>

        <Cell>
          <CopyImplantText item={"MR-807"} auth={auth} /> longer webbing range, for
          <b> vindicator only pilots.</b> <br />
          <CopyImplantText item={"Zor's Custom Navigation Hyper-Link"} auth={auth} />{" "}
          increased MWD speed for
          <b> DPS only pilots.</b>
        </Cell>
      </Row>
      <Row>
        <Cell>
          <b>Slot 9</b>
        </Cell>
        <Cell>
          <CopyImplantText item={"RF-906"} auth={auth} /> increased rate of fire.
        </Cell>

        <Cell>
          <CopyImplantText item={"Pashan's Turret Customization Mindlink"} auth={auth} />{" "}
          if you have too much isk, increased rate of fire.
        </Cell>
      </Row>
      <Row>
        <Cell>
          <b>Slot 10</b>
        </Cell>
        <Cell>
          <b>Kronos/Vindicator:</b>
          <br />
          <CopyImplantText item={"LH-1006"} auth={auth} /> increased hybrid weapon damage.{" "}
          <br />
          <br />
          <b>Paladin/Nightmare:</b>
          <br />
          <CopyImplantText item={"LE-1006"} auth={auth} /> increased energy weapon damage.
        </Cell>
        <Cell>
          <CopyImplantText item={"HG-1006"} auth={auth} /> or
          <br />
          <CopyImplantText item={"HG-1008"} auth={auth} /> if you have too much isk,
          increased RAW armor HP for
          <b> logi only pilots.</b> <br />
          <br />
          <b>Paladin/Nightmare:</b>
          <br />
          <CopyImplantText item={"Pashan's Turret Handling Mindlink"} auth={auth} /> if you
          have too much isk, increased weapon damage.
        </Cell>
      </Row>
    </>
  );
}
