import { useApi } from "../../../api";
import { CharacterName } from "../../../Components/EntityLinks";
import { Title } from "../../../Components/Page";
import styled from "styled-components";
import Spinner from "../../../Components/Spinner";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Copyable } from "../../../Components/Copy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaste } from "@fortawesome/free-solid-svg-icons";

const MemberDisplay = styled.div`
  margin-bottom: 25px;

  div {
    padding: 5px;
    width: 100%;
  }

  button {
    background-color: transparent;
    border: none;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    padding: 0;
  }
`;

const Members = ({fleet_id}) => {

  const copyToClipboard = () => {
    let text = '';
    members.forEach(member => {
      text += `${member.main.name},${(member.duration / 3600).toFixed(2)}\n`;
    })
    Copyable(text);
  }


  const [members] = useApi(`/api/v2/fleets/${fleet_id}/history/members`)
  return (
    <MemberDisplay>
      <Title>
        Fleet Members <span className="cursor-pointer" onClick={copyToClipboard}><FontAwesomeIcon icon={faPaste} fixedWidth /></span>
      </Title>
      <ul className="divide-y divide-gray-100">
        {!members ? (
          <li className="relative flex justify-between gap-x-6 py-5">
            <Spinner />
          </li>
        ) : members?.length > 0 ? (
          members?.map((member, key) => {
            return (
              <li key={key} className="relative flex flex-col justify-between">
                <Disclosure>
                  <DisclosureButton className="w-full group">
                    <div style={{
                      marginBottom: "5px",
                      padding: "0",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                    }}>
                      <CharacterName {...member.main} /> - {(member.duration / 3600).toFixed(2)} hours
                      <ChevronDownIcon className="size-5 fill-white/60 group-data-[hover]:fill-white/50 group-data-[open]:rotate-180" />
                    </div>
                  </DisclosureButton>
                  <DisclosurePanel>
                    <div style={{ marginBottom: "5px" }}>Characters used in fleet</div>
                    {member.characters.map((char) => {
                      return (<div key={char.id}
                                    style={{
                                      marginBottom: "5px",
                                      paddingInlineStart: "5%",
                                    }}><CharacterName {...char} />
                  </div>);
                    })}
                  </DisclosurePanel>
                </Disclosure>

                {/*<span style={{marginBottom: '5px'}}>Start Time: {boss.start_time}</span>*/}
                {/*<span style={{marginBottom: '10px'}}>End Time: {boss.end_time}</span>*/}
              </li>
            );
          })
        ) : (
          <li className="relative flex justify-between gap-x-6 py-5">
            <em style={{ fontSize: "smaller" }}>Fleet had no logged members, so was likely a fail form</em>
          </li>
        )}
      </ul>
    </MemberDisplay>
  );
};


export default Members;
