import styled from "styled-components";


const AnnouncementBar = styled.div`
  background: ${(props) => props.theme.colors.secondary.color};
  box-shadow: 0px 3px ${(props) => props.theme.colors.shadow};
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 3.5px;
  padding: 12px;
  position: relative;

  &[data-alert="true"] {
    background: ${(props) => props.theme.colors.danger.color};
    color: ${(props) => props.theme.colors.danger.text};
  }

  small {
    font-style: italic;
    font-size: small;
  }

  .close {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 32px;
    height: 32px;
    opacity: 0.6;
  }
  .close:hover {
    opacity: 1;
    transition: ease-in-out 0.2s;
    cursor: pointer;
  }
  .close:before,
  .close:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 15px;
    width: 2px;
    background-color: ${(props) => props.theme.colors.secondary.text};
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
`;

const MissingScopesBanner = () => {
  return (
    <div style={{ marginBottom: "10px" }}>
      <AnnouncementBar data-alert={true}>
        <p
          style={{ paddingLeft: "42px" }}>{"The character you have selected is missing the required scopes to FC. Please go to your pilot page and use the button on the right to add the scopes"}</p>
      </AnnouncementBar>
    </div>
  );
};

export default MissingScopesBanner;
