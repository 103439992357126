import App from "./App/";
import { createRoot } from "react-dom/client";
import { createRoutesFromChildren, matchRoutes, useNavigationType } from "react-router-dom";
import React from "react";
import * as Sentry from '@sentry/react'
import { useLocation } from "react-router-dom";

if(process.env.REACT_APP_SENTRY_ON === '1'){
  Sentry.init({
    environment: process.env.NODE_ENV,
    release: process.env.REACT_APP_SENTRY_COMMIT,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      }),
      Sentry.replayIntegration()
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.2,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost:3000", /^\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.5,
  });
}


const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />)
