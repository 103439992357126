import { useApi } from "../../../api";
import { CharacterName } from "../../../Components/EntityLinks";
import { Title } from "../../../Components/Page";
import styled from "styled-components";
import Spinner from "../../../Components/Spinner";

const BossDisplay = styled.div`
  margin-bottom: 25px;

  div {
    padding: 5px;
    width: 100%;
  }

  button {
    padding: 5px;
    font-size: 10px;
    margin-left: 5px;
  }
`;

const Bosses = ({fleet_id}) => {
  const [bosses] = useApi(`/api/v2/fleets/${fleet_id}/history/bosses`)
  return (
    <BossDisplay>
      <Title>
        Fleet Bosses
      </Title>
      {!bosses ? (
        <Spinner />
      ) : bosses?.length > 0 ? (
        bosses?.map((boss, key) => {
          return (
            <div key={key} style={{ display: 'flex', flexDirection: 'column'}}>
              <span style={{marginBottom: '5px'}}><CharacterName {...boss} /> - {(boss.duration/3600).toFixed(2)} hours</span>
              <span style={{marginBottom: '5px'}}>Start Time: {boss.start_time}</span>
              <span style={{marginBottom: '10px'}}>End Time: {boss.end_time}</span>
            </div>
          );
        })
      ) : (
        <em style={{ fontSize: "smaller" }}>Fleet had no logged bosses, this is a bug, please alert the Dev</em>
      )}
    </BossDisplay>
  );
};


export default Bosses;
