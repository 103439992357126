import { Badge } from "./Badge";
import { Col, Row } from "react-awesome-styled-grid";

import styled from "styled-components";
import _ from "lodash";
import { useApi } from "../api";
import { useMemo } from "react";

const SkillDom = {};

SkillDom.Table = styled.div`
  margin-bottom: 2em;
`;

SkillDom.Table.Name = styled.h3`
  border-bottom: solid 2px ${(props) => props.theme.colors.accent2};
  font-weight: bolder;
  padding: 0.75em;
`;

SkillDom.Table.Row = styled.div`
  display: flex;
  padding: 0.5em 0.75em 0.5em 0.75em;
  border-bottom: solid 1px ${(props) => props.theme.colors.accent2};

  &:last-child {
    border-bottom: none;
  }
  &:nth-child(odd) {
    background-color: ${(props) => props.theme.colors.accent1};
  }
  > :last-child {
    margin-left: auto;
  }
`;

const categoryOrder = [
  "Tank",
  "Engineering",
  "Drones",
  "Navigation",
  "Gunnery",
  "Targeting",
  "Neural Enhancement",
  "Spaceship Command",
];
const knownCategories = new Set(categoryOrder);

const LevelIndicator = ({ current, skill }) => {
  let nextLevel = null;
  if (current === 5 || current === skill) {
    return <Badge variant="success">{current}</Badge>;
  } else {
    nextLevel = ` / ${skill}`;
    return <Badge variant="danger">
      {current}
      {nextLevel}
    </Badge>;
  }
};

function SkillTable({ title, current, category }) {
  const entries = [];
  category.forEach((skill) => {
    if (skill.level <= current[skill.id]) {
      return;
    }

    entries.push(
      <SkillDom.Table.Row key={skill.id}>
        {skill.name} <LevelIndicator current={current[skill.id]} skill={skill.level} />
      </SkillDom.Table.Row>
    );
  });

  if (!entries.length) {
    return null;
  }

  return (
    <Col xs={4} sm={4} md={2}>
      <SkillDom.Table>
        <SkillDom.Table.Name>{title}</SkillDom.Table.Name>
        {entries}
      </SkillDom.Table>
    </Col>
  );
}

function SkillsTable({category, tier, pilotSkills}) {
  const [ skills ] = useApi(`/api/skills/category/${category.id}/tier/${tier.id}/skills`);

  return (<>
    {skills && Object.keys(skills).map((category) => (
      <SkillTable
        key={category}
        title={category}
        current={pilotSkills}
        category={skills[category]}
      />
    ))}
  </>);
}

export function SkillList({ mySkills, ship }) {
  const shipName = ship?.name;
  const [ tiers ] = useApi(`/api/skills/category/${ship.id}/tiers`);

  const tier = useMemo(() => {
    if(tiers === undefined || tiers === null){
      return undefined;
    }
    return tiers[0];
  }, [tiers])

  if (!shipName) {
    return <em>No skill information found</em>;
  }

  const categories = [...categoryOrder];
  _.forEach(_.keys(mySkills.categories), (categoryName) => {
    if (!knownCategories.has(categoryName)) {
      categories.push(categoryName);
    }
  });

  return (
    <>
      <Row>
        {tier && (
          <SkillsTable
          category={ship}
          tier={tier}
          pilotSkills={mySkills.current}
          />
        )}
      </Row>
    </>
  );
}

export function Legend() {}

export function SkillDisplay({ ship, characterId }) {
  const [skills] = useApi(`/api/skills?character_id=${characterId}`);
  return (
    <>
      { skills ? (
        <SkillList mySkills={skills} ship={ship} />
      ) : (
        <p>Loading skill information</p>
      )}
    </>
  );
}
